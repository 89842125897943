import React from "react"
import LayoutPage from "../components/layout-page"
import SEO from "../components/seo"
import ImageMeta from "../components/ImageMeta"

const PrivacyPage = () => (
  <LayoutPage>
    <SEO title={"Privacy"} meta={ImageMeta} />
    <section className="terms bg-light page-padding pb-90">
      <div className="container px-xl-0">
        <div className="row justify-content-center">
          <div className="col-xl-8 col-lg-10 text-center pb-25">
            <h2 className="small" data-aos-duration="600" data-aos="fade-down" data-aos-delay="0">
              Privacy Policy
            </h2>
          </div>
        </div>
        <div class="markdownPage">
          <div class="Col Col--10 Offset--0 Col-mobile--12 ">
            <div>
              <h4>Welcome to mix:analog!</h4>
              <p></p>
              <p>
                We value your information very much. In this Privacy Policy, we want to help you understand how we treat the information you have shared with us through mix:analog,
                including our services, our websites in the mix:analog domain, our blog, as well as our software (collectively the “Services”). This way, you can make informed
                decisions about the relationship between us and you. So please read it carefully.
              </p>
              <p>
                By using our Services, you agree to the terms and conditions set forth in this Privacy Policy. This Privacy Policy is incorporated into, and is subject to, our
                Terms of Service. Throughout this Privacy Policy, we will also refer to terms defined in our Terms of Service.
              </p>
              <p></p>
              <h4>Your Information</h4>
              <p></p>
              <p>
                We work for musicians! And in order to provide you with our Services, we need to collect information from you to best serve your needs. Therefore, we may collect
                various types of information through the Services we provide. You can rest assured, we strictly use your “Personal Information” (i.e. information that personally
                identifies you or could be used to personally identify you) to provide the Services. We may also process Personal Information upon your consent.
              </p>
              <p></p>
              <h4>What you share with us</h4>
              <p></p>
              <p>When you use our Services, you may share with us and we may collect Personal Information which include (but is not limited to) the following:</p>
              <ul>
                <li>Your email address when you subscribe to our blog;</li>
                <li>Your last name, first name, email address and mailing address when you create an account;</li>
                <li>Your payment information when you buy a paid subscription;</li>
                <li>Your language of preference, subscription plan, billing information, mastering history and promotional code associated with your account, where applicable;</li>
                <li>
                  Your files, content, configuration parameters and other information (your “Data”), including your photos, audio files, production preferences, structural data and
                  emails as well as ancillary information (for example, musical style or type of instrument);
                </li>
                <li>Your list of contacts, if you grant us access;</li>
                <li>Your visitor profile on our website and blog;</li>
                <li>Your interactions with us, whether by email, on our blog or on our social media platforms.</li>
              </ul>
              <h4>What is shared with us through our Services</h4>
              <p></p>
              <p>We may also collect the following information when you use our Services:</p>
              <ul>
                <li>
                  We collect information on the devices that you use to access our Services. This information includes things like your IP address, web browser and the device that
                  you use, the webpage that linked you to our sites and the identifiers associated with your devices. If you have activated the location service parameters on your
                  devices, these can also send us information about your geographical location;
                </li>
                <li>
                  We use “cookies” to collect information and to operate and improve our Services. A cookie is a small data file that we store on your electronic device. We may use
                  “persistent cookies” to record your email and password for when you log on to our Services in the future. We may use “session identifier cookies” to activate
                  certain features of the Services, to better understand and improve your experience with the Services, to track data about the overall use of the Services (length
                  of visits, number of users, etc.) and to optimize the marketing of our Services;
                </li>
                <li>
                  Most web browsers allow you to refuse cookies, or ask for your consent before you accept cookies. However, if you do not accept cookies, you might not be able to
                  use all of our Services’ features;
                </li>
                <li>
                  We may also combine information that we collect through our Services with information that we collect from other sources. For example, if you log on to your
                  mix:analog account through Facebook, you give us access to information associated with your Facebook profile .
                </li>
              </ul>
              <h4>How we treat Your Information</h4>
              <p></p>
              <p>
                We take matters regarding your information very seriously. We make sure that we use your Personal Information only when we absolutely need it to fulfil our duties
                to you and only give access to those employees who need to have access to it to fulfil their duties to you.
              </p>
              <p></p>
              <p>Therefore, we may use the information that we collect in a variety of ways to provide the Services, including:</p>
              <ul>
                <li>To authenticate you when you log on to your account or the blog;</li>
                <li>To provide you with the requested subscription and to proceed with payment;</li>
                <li>To access, store and analyze your Data so that you may enjoy our Services;</li>
                <li>
                  To activate certain features, like previews, editing, sharing and song searches. For example, if you give us access to your contacts, we can store that
                  information to simplify some of your interactions, like sharing your Data or sending out emails or invitations to use our Services;
                </li>
                <li>
                  To understand and analyze the usage trends and preferences of our users, to improve the Services, and to develop new products, services, features, and
                  functionalities;
                </li>
                <li>
                  To improve the performance of our mastering algorithm and music engine based on the music you upload. You, the musician, retains ownership, copyright, and
                  publishing rights of your music;
                </li>
                <li>To follow up on any question and request for assistance or information;</li>
                <li>To understand your needs and preferences, and to assess your level of satisfaction with our Services;</li>
                <li>
                  To send you updates on our products, services, news and events and to follow up on our offers, promotions or contests (for example, to send you your referral
                  credits when you have referred someone to our Services), to the extent permitted by applicable law;
                </li>
                <li>To comply with legal and regulatory requirements, where applicable.</li>
              </ul>
              <h4>How we may share Your Information</h4>
              <p></p>
              <p>
                Don’t worry, your Personal Information is in safe hands. We absolutely do not sell or market your Personal Information to third parties. Your Data is yours.
                However, to provide you the Services, we may share certain information that we collect from you in the following manner:
              </p>
              <ul>
                <li>Within our family of companies, including parents, corporate, affiliates, subsidiaries, business units and other companies that share common ownership;</li>
                <li>
                  To our service providers or partners, so that they can help us provide, improve and promote our Services, but only when they require such information to perform
                  their services. We may provide Personal Information to our providers and partners to process it for us based on our instruction and in compliance with our privacy
                  policy;
                </li>
                <li>
                  For business transactions relating to any reorganization, merger, sale of assets, funding or acquisition of all or part of our activities by another corporation,
                  including in the context of a due diligence or closing process;
                </li>
                <li>
                  In all cases where sharing your information is permitted or required by applicable law (for example, court order, situation that threatens the life, health or
                  safety of an individual).
                </li>
              </ul>
              <p>
                When we share your Personal Information to third parties, we take reasonable measures to ensure that those third parties follow the rules in this Privacy Policy,
                and that they provide sufficient guarantees to implement the appropriate technical and organizational measures.
              </p>
              <p></p>
              <p>
                We may share certain automatically-collected, aggregated, or otherwise non-personally-identifiable information with third parties for various purposes, including
                (i) &nbsp;for following various reporting obligations; (ii) for business or marketing purposes; or (iii) to assist such parties in understanding your interests,
                habits, and usage patterns for certain programs, content, services, and/or functionality available through the Services. In short, whenever we share your
                information with others, it is to best serve your needs.
              </p>
              <p></p>
              <h4>Your Preferences</h4>
              <p></p>
              <p>To give you the ability to control how you share your information with us, we offer you various options. Here are a few:</p>
              <ul>
                <li>
                  You may be able &nbsp;to access or register for the Services through third parties (each, an “Integrated Service”), such as through your Facebook account, or
                  otherwise allow an Integrated Service to provide Personal Information or other information to us. By allowing &nbsp;us to connect with an Integrated Service, you
                  authorize us to access and store your name, email address(es), and other information that the Integrated Service shares with us, and to use and disclose it
                  according to this Privacy Policy. You should check your privacy settings on each Integrated Service to find out what information is shared with us, and make
                  changes according to your preferences. Please review each Integrated Service’s terms of use and privacy policies carefully before using their services to connect
                  to our Services;
                </li>
                <li>
                  You can edit your contact information and language of communication at all times. This is the best way to ensure that your Personal Information is accurate and up
                  to date;
                </li>
                <li>You can change your password at all times;</li>
                <li>You can subscribe and unsubscribe to our promotional offers;</li>
                <li>You can log out of your account securely by clicking on the “Log Out” button;</li>
                <li>
                  You can delete your account at all times by contacting our customer support team at&nbsp;<a href="mailto:support@mixanalog.com">support@mixanalog.com</a>.
                </li>
              </ul>
              <h4>Other Websites and Social Media</h4>
              <p></p>
              <p>
                Our website and blog may have links to third party websites like Facebook, Twitter, Instagram, SoundCloud or others. We can’t control or be held responsible
                for the privacy practices of these third parties. Some features, such as data sharing, offer you the possibility of granting third parties access to your
                information. For example, through API interfaces. Please be aware that how these third parties handle your information is under the privacy policies of those third
                parties. Also bear in mind that anyone may read, collect and use information that you publish in a public forum such as our blog or on social media.
              </p>
              <p></p>
              <h4>Referral Campaigns</h4>
              <p></p>
              <p>
                We may from time to time invite you to share a promotional offer or to refer our Services to members of your family or close friends. In such cases, please make
                sure that the people you refer us to are indeed members of your family (spouse, common-law partner, parent or child) or are people with whom you have a personal
                relationship (frequent communications, shared affinities, opinions, etc.). We would really appreciate it if you referred us only to people you closely know who’d be
                interested in our Services.
              </p>
              <p></p>
              <h4>How we manage Your Information</h4>
              <p></p>
              <p>
                mix:analog may store or process your Personal Information in other countries where we have facilities or have third party service providers. By using the Service,
                you consent to the transfer of information to countries outside your country of residence, including the United States. Therefore, your Personal Information may be
                subject to data protection and privacy laws different than in your country. While such information is outside of Canada, it is subject to the laws of the country in
                which it is held, and may be subject to disclosure to the governments, courts or law enforcement or regulatory agencies of such other country, in accordance to the
                laws of such country. However, our practices regarding your Personal Information will always be &nbsp;governed by this Privacy Policy and, if applicable, we will
                comply with the General Data Protection Regulation (“GDPR”) requirements, providing adequate protection for the transfer of Personal Information from the EU/EEA to
                a third country. Information can also be stored locally on the devices that you use to access our Services.
              </p>
              <p></p>
              <p>
                We store your Data as long as we need it to provide the Services to you, or as required by applicable laws, regulations, or government orders. If you delete your
                account, we will also delete all of your Personal Information. However, please note that: (1) there may be a delay before this information is deleted from our
                servers and our backup servers; and (2) our legal obligations might require us to retain some information. With the exception of paid accounts, we reserve the right
                to delete your account if you fail to access our Services for twelve (12) consecutive months. Before deleting your account, we will send you a notice to your email
                address.
              </p>
              <p></p>
              <h4>Security</h4>
              <p></p>
              <p>
                We are very committed to protecting your Personal Information. We follow generally accepted industry standards to protect the information you submit to us, both
                during transmission and once we receive it. We maintain appropriate physical, technical and administrative safeguards to protect your Personal Information against
                accidental or unlawful destruction, accidental loss, unauthorized alteration, unauthorized disclosure or access, misuse, and any other unlawful form of processing
                of your Personal Information in our possession. However, no method of transmission over the Internet, or method of electronic storage, is 100% secure. We cannot
                ensure or warrant the security of any information you transmit to us or store on the Service, and you do so at your own risk. We also cannot guarantee that such
                information may not be accessed, disclosed, altered, or destroyed by breach of any of our physical, technical, or administrative safeguards. If you believe your
                Personal Information has been compromised, please contact us as described in the “Contact Information” section. If we learn of a security systems breach, we will
                inform you and the authorities of the occurrence of the breach in accordance with applicable law.
              </p>
              <p></p>
              <h4>Right to Access and Correct</h4>
              <p></p>
              <p>
                Your Personal Information is 100% yours and you have the right to access or request to correct whatever Personal Information you have shared with us at any time by
                writing to us.
              </p>
              <p></p>
              <p>
                Under the GDPR, you may have additional rights, including: (i) the right to withdraw consent to processing where processing requires consent; (ii) the right to
                access your Personal Information and certain other supplementary information, under certain conditions; (iii) the right to refuse &nbsp;unlawful data processing,
                under certain conditions; (iv) the right to remove Personal Information about you, under certain conditions; (v) the right to demand that we limit the processing of
                your Personal Information, under certain conditions, if you believe we have exceeded the legitimate basis for processing, processing is no longer necessary, are
                processing, or believe your Personal Information is inaccurate; (vi) the right to move, copy, or transfer (i.e., data portability) of your Personal Information that
                you provided us in a structured, commonly used, and machine-readable format, under certain conditions; (vii) the right to refuse automated procedures that may have
                legal effects for you , under certain conditions; (viii) the right to send a complaint to data protection authorities. If you want to learn more about your rights
                under the GDPR, you can visit the European Commission’s page on Data Protection at: <a href={"https://ec.europa.eu/info/law/law-topic/data-protection_en"}>https://ec.europa.eu/info/law/law-topic/data-protection_en</a>.
              </p>
              <p></p>
              <h4>Update</h4>
              <p></p>
              <p>
                Our Privacy Policy may be changed occasionally depending on how mix:analog changes. This doesn’t mean that your Personal Information will be at any risk. If we make
                any such change, we will post a notice to that effect on our website to inform you of the date on which these amendments will come into effect. Therefore, we
                suggest you to keep an eye on our website to stay informed of any possible updates. If you continue using our Services after the changes to our Privacy Policy comes
                to effect, we will understand it as you acknowledging and accepting the changes. The date of the last update is the one that appears at the bottom of the following
                page.
              </p>
              <p></p>
              <h4>Contact Information</h4>
              <p></p>
              <p>
                If you have any comments, any questions or any inquiry about this Policy or your Personal Information, or if you want to obtain information about our policies and
                practices with respect to any service providers outside Canada, please contact our Privacy Officer (or Data Protection Officer) by mail or email using the following
                contact information:
              </p>
              <p></p>
              <p>
                Distopik d.o.o.
                <br />
                Tobačna ulica 5<br />
                1000 Ljubljana
                <br />
                Slovenia
              </p>
              <p></p>
              <p>This Privacy Policy was last updated on August 14, 2019</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </LayoutPage>
)

export default PrivacyPage
